document.addEventListener('turbolinks:load', () => {
  $( ".date" ).datepicker( {
    dayNames: ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'],
    dayNamesMin: ['D','S','T','Q','Q','S','S','D'],
    dayNamesShort: ['Dom','Seg','Ter','Qua','Qui','Sex','Sáb','Dom'],
    monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
    monthNamesShort: ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'],
    nextText: 'Próximo',
    prevText: 'Anterior',
    changeMonth: true,
    changeYear: true,
    showButtonPanel: true,
    dateFormat: 'dd/mm/y',
  });
  //$(".date").datepicker().datepicker("setDate", new Date());
  $( ".cancel_transaction" ).click(function() {
    Swal.fire({
      title: 'Cancelar por ID da Transação',
      text: "Informe o ID",
      icon: 'warning',
      input: 'text',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Cancelar!',
      cancelButtonText: 'Não!'
    }).then((result) => {
      console.log(result)
      if (result.value) {
        window.location.href = "/invoices?status=1&cancel_transaction=" + result.value;
      }
    })
  });
  $( ".order_card" ).click(function() {
    order_id = $(this).attr('id');
    getModal(order_id)
  });
  function getModal(order_id) {
    //console.log(order_id)
    $.get( "/order_modal?order_id=" + order_id, function( data ) {
      $( "#orderModal_" + order_id).find(".modal-body").html( data );
      $( "#orderModal_" + order_id).modal()
    });
  }
});
