document.addEventListener('turbolinks:load', () => {  
  if ($("body").hasClass("users-index")){
    $( ".new_user_email" ).click(function() {    
      Swal.fire({
        title: 'Informe o email',
        input: 'text',        
        showCancelButton: true,
        inputValidator: (value) => {
          console.log(value)
          if (!value) {
            return 'Informe um email!'
          }
          $.post('/add_user_email?email=' + value, {}, function (data, textStatus, jqXHR) {
            if(data.error){
              Swal.fire("Usuário não encontrado !")
            }else{
              window.location.href = "/users"
            }
          })
        }
      });  
    });  
  }
});