document.addEventListener('turbolinks:load', () => {
  if ($("body").hasClass("orders-index")){
    //console.log( "ready!" );
    var audio = document.getElementById("yes-audio");
		audio.play();
    let count = $("#status_1").find('.order_card:not(#order_new)').length;
    var newTitle = '(' + count + ') ' + " - 99Farma - " + $("#current_user").val();
    document.title = newTitle;
    function getModal(order_id) {
      //console.log(order_id)
      $.get( "/order_modal?order_id=" + order_id, function( data ) {
        $( "#orderModal_" + order_id).find(".modal-body").html( data );
        cancelOrder()
      });
    }

    var title = document.title;
    setInterval(function(){
      $.get('/get_order_new', {}, function (data, textStatus, jqXHR) {
        console.log(data)
        let count = data.length;
        var newTitle = '(' + count + ') ' + " - 99Farma - " + $("#current_user").val();
        document.title = newTitle;
        for ( var i = 0, l = data.length; i < l; i++ ) {
          div = $( "#order_new" ).clone()
          $(div).find('#slug').val(data[i].slug)
          $(div).find('#order_id').val(data[i].id)
          $(div).attr('id', 'order_' + data[i].id)
          $(div).attr('data-target', '#orderModal_' + data[i].id)
          $(div).find('.order_slug').html(data[i].slug + " " + data[i].user.name);
          $(div).find('.card-body').css("padding", "1.25rem");
          $(div).css("display", "block");
          console.log(data[i].id)
          if(data[i].status == 'Pago'){
            $( "#status_1" ).append( div );
          }else{
            $( "#status_4" ).append( div );
          }
          orderModal_new = $( "#orderModal_new" ).clone()
          $(orderModal_new).find('.modal-title').html(data[i].slug + " " + data[i].user.name);
          $(orderModal_new).attr('id', 'orderModal_' + data[i].id)
          $( ".board_orders" ).parent().append( orderModal_new );
          $.get('/update_order_new?order_id=' + data[i].id, {}, function (data, textStatus, jqXHR) {});
          $("#status_5").find('#order_' + data[i].id).hide();
        }
        if(count > 0){
          var audio = document.getElementById("yes-audio");
		      audio.play();
        }
        $( ".order_card" ).click(function() {
          order_id = $(this).attr('id').split("_")[1];
          getModal(order_id)
        });
        $(".order_card").draggable({
          appendTo: "body",
          cursor: "move",
          helper: 'clone',
          revert: "invalid"
        });
      });
    }, 30000);

    $( "#open_close" ).click(function() {
      //console.log($( "#open_close" ).is(":checked"))
      if($( "#open_close" ).is(":checked")){
        $("#label_open_close").html("Loja Aberta")
      }else{
        $("#label_open_close").html("Loja Fechada")
      }
      $.get('/open_close?open_close=' + $( "#open_close" ).is(":checked"), {}, function (data, textStatus, jqXHR) {})
    });

    $( ".order_card" ).click(function() {
      order_id = $(this).attr('id').split("_")[1];
      getModal(order_id)
    });

    $( ".order_card_finished" ).click(function() {
      order_id = $(this).attr('id').split("_")[1];
      getModal(order_id)
    });

    $(".order_card").draggable({
      appendTo: "body",
      cursor: "move",
      helper: 'clone',
      revert: "invalid"
    });

    $("#status_1").droppable({
      tolerance: "intersect",
      accept: ".card",
      activeClass: "ui-state-default",
      hoverClass: "ui-state-hover",
      drop: function(event, ui) {
        var id = $(ui.draggable)[0].id.split("_")[1]
        $.post( "change_status", { order_id: id, status: 1 }).done(function( data ) {});
        $(this).append($(ui.draggable));
        var card = $($(ui.draggable)[0]);
        $(card).removeClassStartingWith('border-left');
        $(card).addClass('border-left-warning');
        let count = $("#status_1").find('.order_card:not(#order_new)').length;
        var newTitle = '(' + count + ') ' + " - 99Farma - " + $("#current_user").val();
        document.title = newTitle;
      }
    });

    $("#status_2").droppable({
      tolerance: "intersect",
      accept: ".card",
      activeClass: "ui-state-default",
      hoverClass: "ui-state-hover",
      drop: function(event, ui) {
        $(this).append($(ui.draggable));
        var card = $($(ui.draggable)[0]);
        $(card).removeClassStartingWith('border-left');
        $(card).addClass('border-left-primary');
        var id = $(ui.draggable)[0].id.split("_")[1]
        $.post( "change_status", { order_id: id, status: 2 }).done(function( data ) {});
      }
    });

    $("#status_3").droppable({
      tolerance: "intersect",
      accept: ".card",
      activeClass: "ui-state-default",
      hoverClass: "ui-state-hover",
      drop: function(event, ui) {
        $(this).append($(ui.draggable));
        var card = $($(ui.draggable)[0]);
        $(card).removeClassStartingWith('border-left');
        $(card).addClass('border-left-success');
        $(card).removeClass('order_card');
        $(card).draggable("option", "disabled", true);
        var id = $(ui.draggable)[0].id.split("_")[1]
        $.post( "change_status", { order_id: id, status: 3 }).done(function( data ) {});
      }
    });

    function cancelOrder(){
      $( ".cancel_order" ).click(function() {
        order_id = $(this).attr('id');
        //console.log(order_id)
        Swal.fire({
          title: 'Tem certeza?',
          text: "Será cancelado com a operadora do cartão também!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Cancelar!',
          cancelButtonText: 'Não!'
        }).then((result) => {
          if (result.value) {
            $.post( "change_status", { order_id: order_id, status: 4 }).done(function( data ) {});
            $("#order_" + order_id).hide()
            Swal.fire(
              'Cancelado!',
              '',
              'success'
            )
          }
        })
      });
    }

    $("#status_4").droppable({
      tolerance: "intersect",
      accept: ".card",
      activeClass: "ui-state-default",
      hoverClass: "ui-state-hover",
      drop: function(event, ui) {
        $(this).append($(ui.draggable));
        var card = $($(ui.draggable)[0]);
        $(card).removeClassStartingWith('border-left');
        $(card).addClass('border-left-danger');
        var id = $(ui.draggable)[0].id.split("_")[1]
        $.post( "/change_status", { order_id: id, status: 4 }).done(function( data ) {});
      }
    });

    $.fn.removeClassStartingWith = function (filter) {
      $(this).removeClass(function (index, className) {
          return (className.match(new RegExp("\\S*" + filter + "\\S*", 'g')) || []).join(' ')
      });
      return this;
    };
  }
  if ($("body").hasClass("orders-show")){
    $( ".cancel_order" ).click(function() {
      order_id = $("#order_id").val()
      //console.log(order_id)
      Swal.fire({
        title: 'Tem certeza?',
        text: "Informe o motivo para o cliente",
        icon: 'warning',
        input: 'text',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Cancelar!',
        cancelButtonText: 'Não!'
      }).then((result) => {
        console.log(result)
        if (result.value) {
          $(".cancel_order").hide()
          window.location.href = "/cancel_receita?order_id=" + order_id + "&observation=" + result.value;
        }
      })
    });
    $("#order_items").bind('cocoon:after-insert', function(e, inserted_item) {
      inserted_item.find('.money').mask('#.##0,00', {reverse: true});
      inserted_item.trigger("blur.autocomplete")
      $(inserted_item).autocomplete({
        delay: 1000,
        minLength: 3,
        source: function( request, response ) {
         $.ajax({
          url: "/search_order_item.json",
          type: 'get',
          dataType: "json",
          data: {
            q: $(inserted_item).find(".autocomplete").val()
          },
          success: function( data ) {
            console.log(data)
            response( data );
          }
         });
        },
        select: function (event, ui) {
          // Set selection
          console.log(ui)
          $(inserted_item).find(".autocomplete").val(ui.item.label)
          $(inserted_item).find(".product_id").val(ui.item.value)
          $(inserted_item).find(".unit_value_cents").val(ui.item.price)
          $(inserted_item).find(".unit_value_cents").trigger('input');
          $(inserted_item).find(".unit_value_cents").focus();
          $(this).val('');
          return false;
        }
       });
       inserted_item.keypress(function() {
        $(inserted_item).autocomplete("search", $(inserted_item).find(".autocomplete").val());
      });
    })
  }

});
