document.addEventListener('turbolinks:load', () => {
  $( ".product_warn" ).click(function() {
    let ids = [];
    $("#product_warn :checkbox:checked").each(function( index ) {
      console.log( $( this ).attr('id') );
      ids.push($( this ).attr('id'));
    });
    if(ids.length > 0){
      $('.link_remover').show();
      $('#link_remover').attr("href", "product_warns?remove=true&ids=" + [ids]);
    }else{
      $('.link_remover').hide();
    }
  })
  $( ".send_warn" ).click(function() {
    product_warn_id = $(this).attr('id');
    Swal.fire({
      title: 'Enviar mensagem',
      text: "",
      inputValue: "Aviso de produto em estoque 😀 confira agora no app",
      icon: 'warning',
      input: 'textarea',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Enviar!',
      cancelButtonText: 'Cancelar!'
    }).then((result) => {
      //console.log(product_warn_id)
      //console.log(result)
      if (result.value) {
        window.location.href = "/send_warn?product_warn_id=" + product_warn_id + "&message=" + result.value;
      }
    })
  });
});
