document.addEventListener('turbolinks:load', () => {
  $('.money').mask('#.##0,00', {reverse: true});
  $("#company_bank").selectize();
  var selector = document.getElementById("company_cnpj");
  if (selector) {
    var im = new Inputmask("99.999.999/9999-99");
    im.mask(selector);
  }

  var selector = document.getElementById("company_cpf");
  if (selector){
    var im = new Inputmask("999.999.999-99");
    im.mask(selector);
  }

  var selector = document.getElementById("company_cep");
  if(selector){
    var im = new Inputmask("99999-999");
    im.mask(selector);
  }

  var selector = document.getElementById("company_telephone");
  if(selector){
    var im = new Inputmask(['(99)9999-9999', '(99)99999-9999']);
    im.mask(selector);
  }

  var selector = document.getElementById("company_whatsapp");
  if(selector){
    var im = new Inputmask(['(99)9999-9999', '(99)99999-9999']);
    im.mask(selector);
  }

  var times = document.getElementsByClassName("form-control-time");
  if(times){
    for (var i = 0; i < times.length; i++) {
      var im = new Inputmask("99:99");
      im.mask(times[i]);
    }
  }

  $('#deliveries').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
    console.log('a')
    inserted_item.find('.money').mask('#.##0,00', {reverse: true});
  });

  $("#company_cep").blur(function(event){
    console.log($("#company_cep").val())
    console.log($("#company_cep").val().length)

    if( $("#company_cep") && $("#company_cep").val().length == 9){
      $.getJSON("https://viacep.com.br/ws/" + $("#company_cep").val().replace("-","") + "/json/", function(data) {
        console.log(data)
        if(data){
          $("#company_streat").val(data.logradouro);
          $("#company_city").val(data.localidade);
          $("#company_neighborhood").val(data.bairro);
          $("#company_state").val(data.uf);
        }
      });
      $.getJSON("https://maps.googleapis.com/maps/api/geocode/json?address=" + $("#company_cep").val().replace("-","") + "&sensor=false&key=AIzaSyCRMMoYEZgricr12dxzi6CKWgxnU-HEVW8", function(data) {
        console.log(data)
        if(data && data.results[0]){
          $("#company_company_lat").val(data.results[0].geometry.location.lat)
          $("#company_company_lng").val(data.results[0].geometry.location.lng)
        }
      });
    }
  });
});
